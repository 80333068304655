<template>
  <div class="app-form-block">
    <div class="mt-time-interval-field">
      <div class="mt-time-interval-field--container">
        <div class="mt-time-interval-field--container__date">
          <app-date-time-picker
            v-model="date.from"
            @update:model-value="updateDate"
            style="margin-bottom: 0"
            :class="{ 'app-input_error': hasError.from }"
            format="dd.MM.yyyy"
            :max-date="date.to"
          >
            <template #label> Время доступа с </template>
          </app-date-time-picker>
        </div>
        <div class="mt-time-interval-field--container__date">
          <app-date-time-picker
            v-model="date.to"
            @update:model-value="updateDate"
            style="margin-bottom: 0"
            :class="{ 'app-input_error': hasError.to }"
            format="dd.MM.yyyy"
            :min-date="date.from"
          >
            <template #label> Время доступа по </template>
          </app-date-time-picker>
        </div>
      </div>
      <div
        class="edit-general-info-block__error-message"
        style="margin-top: 5px"
      >
        <template v-if="hasError.from">
          Дата начала не должна быть позже даты окончания
        </template>
        <template v-else-if="hasError.to">
          Дата окончания не должна быть раньше даты начала
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MtSvg from '@/components/UI/mtSvg/index.vue'
import { format, startOfDay } from 'date-fns'
import AppInput from '@/components/UI/AppInput/AppInput.vue'
import AppDateTimePicker from '../UI/AppDateTimePicker/AppDateTimePicker.vue'
import { createDateFromDDMMYYY } from '@/services/date'

export default {
  components: {
    AppInput,
    MtSvg,
    AppDateTimePicker,
  },
  props: {
    preloader: {
      type: Boolean,
      default: false,
    },
    dateFrom: {
      type: String,
      default: '',
    },
    dateTo: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    datePopoverVisibility: false,
    field: '',
    date: {
      from: '',
      to: '',
    },
    hasError: {
      from: false,
      to: false,
    },
  }),
  watch: {
    // date: {
    //   handler() {
    //     this.$emit('update-date', {
    //       dateFrom: this.date.from,
    //       dateTo: this.date.to,
    //     })
    //   },
    //   deep: true,
    // },
    dateFrom(newValue, oldValue) {
      if(!newValue) this.date.from = null
    },
    dateTo(newValue, oldValue) {
      if(!newValue) this.date.to = null
    }
  },
  methods: {
    updateDate() {
      const dateFrom = this.date.from
        ? format(
            startOfDay(createDateFromDDMMYYY(this.date.from)),
            'yyyy-MM-dd',
          )
        : ''

      const dateTo = this.date.to
        ? format(startOfDay(createDateFromDDMMYYY(this.date.to)), 'yyyy-MM-dd')
        : ''

      this.$emit('update-date', {
        dateFrom: dateFrom,
        dateTo: dateTo,
      })
    },
    setDates(payload = null) {
      if (!payload) return
      this.hasError.from = false
      this.hasError.to = false

      if (this.field === 'dateFrom') {
        this.date.from = payload
        if (this.checkDatesToValid()) {
          this.hasError.from = true
        }
      } else {
        this.date.to = payload
        if (this.checkDatesToValid()) {
          this.hasError.to = true
        }
      }

      this.$emit('update-date', {
        dateFrom: format(startOfDay(new Date(this.date.from)), 'dd-MM-yyyy'),
        dateTo: format(startOfDay(new Date(this.date.to)), 'dd-MM-yyyy'),
      })
    },

    checkDatesToValid() {
      if (this.date.from && this.date.to) {
        const dateFrom = new Date(this.date.from)
        const dateTo = new Date(this.date.to)

        return dateFrom.valueOf() > dateTo.valueOf()
      }

      return null
    },

    clearState(entity) {
      if (entity === 'from') {
        this.date.from = ''
      } else if (entity === 'to') {
        this.date.to = ''
      }

      this.$emit('update-date', {
        dateFrom: this.date.from,
        dateTo: this.date.to,
      })
    },

    /* HANDLERS */
    showPicker(field) {
      this.field = field
      this.datePopoverVisibility = !this.datePopoverVisibility
      setTimeout(() => {
        const popup = document.getElementsByClassName('mt-popover')[0]
        popup.scrollIntoView(true)
      })
    },
    hidePicker() {
      this.datePopoverVisibility = false
    },

  },
  mounted() {
    if (this.dateFrom || this.dateTo) {
      this.date.from = format(
        startOfDay(new Date(this.$props.dateFrom)),
        'dd.MM.yyyy',
      )

      this.date.to = this.dateTo
        ? format(startOfDay(new Date(this?.dateTo)), 'dd.MM.yyyy')
        : ''
    }
  },
}
</script>

<style lang="scss">
.mt-time-interval-field {
  width: 100%;
  &--title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #7c869d;
    margin-bottom: 10px;
  }

  &--container {
    display: flex;
    border-radius: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    margin: 0 -5px;

    &__date {
      margin: 0 5px;
      flex: 0 1 100%;

      &__input {
        height: 56px;
        width: 100%;
        padding: 15px 15px 15px 20px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #b4b4b4;

        &_has-error {
          background-color: rgba(255, 0, 0, 0.3);
        }
      }

      &-title {
        color: #7c869d;
      }

      &-value {
        color: #2a4365;
        min-width: 75px;
        height: 22px;
        width: 100%;
        margin-left: 5px;
      }

      &-icon {
        background-size: cover;
        background: url('../../../src/assets/img/svg/assessment-plans/calendar.svg')
          no-repeat center;
        min-width: 16px;
        height: 16px;
        margin-left: 8px;
      }

      &-close-icon {
        margin-left: 8px;
      }

      &__error-text.edit-general-info-block__error-message {
        height: unset !important;
      }
    }

    @include max-xs {
      flex-wrap: wrap;
      height: unset;

      &__date {
        width: 100%;
        margin: 0;

        & + & {
          margin-top: 15px;
        }
      }
    }
  }
}
</style>
