<script setup lang="ts">

</script>

<template>
  <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M0 17.5H18V19.5H0V17.5ZM10 11.672L16.071 5.6L17.485 7.014L9 15.5L0.515 7.015L1.929 5.6L8 11.67V0.5H10V11.672Z"
        fill="currentColor"
    />
  </svg>
</template>

<style scoped lang="scss">

</style>