import { formatDate } from '@/services/date';
import { isSameYear } from 'date-fns';

export const makeIconOfItem = (isCompleted, isOpened, isExpired, isFailed) => {
  if(isFailed) {
    return {
      name: 'failed',
      className: 'failed'
    }
  }

  if(isCompleted) {
    return {
      name: 'twoticks',
      className: 'completed'
    }
  }

  if((isOpened && !isCompleted) || (isExpired)) {
    return {
      name: 'play',
      className: 'active'
    }
  }


  if (!isOpened && !isExpired) {
    return {
      className: 'scheduled',
      name: 'timer'
    }
  }

  return new Error('No Status Found');
}

export const makeCommonItem = (item) => {
  let format = 'dd MMM';
  const isFromToSameYear = isSameYear(new Date(item?.date_from), new Date(item?.date_to));
  if(!isFromToSameYear) format += ' Y';

  return {
    isCompleted: item.completed,
    isOpened: item.open,
    isFailed: item.failed,
    isStopped: item.isStopped, //isStopped

    dateFromUnformatted: item.date_from,
    dateFrom: formatDate(item.date_from, format),
    dateFromShow: !item.open && !item.isExpired,

    dateToUnformatted: item.date_to,
    dateTo: formatDate(item.date_to, format),
    dateToShow: item.open || (!item.open && item.isExpired),

    uuid: item.uuid,
    name: item.name,
    description: item.description,
    icon: makeIconOfItem(item.completed, item.open, item.isExpired, item.failed),
    btn: makeBtn(item)
  }
}

export const getRecordsSortedByStatus = (items) => {
  return {
    active: getActiveRecords(items),
    finished: getCompletedRecords(items)
  }
}
export const getCompletedRecords = (items) => {
  return items.filter(item => item.isCompleted)
}
export const getActiveRecords = (items) => {
  return items.filter(item => !item.isCompleted)
}

const makeBtn = (item) => {
  if(item.open) {
    return {
      className: 'opened',
      label: 'Открыть'
    }
  }

  if (!item.open) {
    let label = ''
    if(item.isExpired) {
      label = 'Недоступно'
    } else {
      label = 'Пока недоступно'
    }
    return {
      className: 'disabled',
      label: label
    }
  }

  return new Error('No Status Found');

}