<template>
  <div class="footer">
    <div class="footer__copyright">© MainTest {{ new Date().getFullYear() }} Все права защищены</div>
    <router-link
        class="footer__link"
        :to="{
          name: 'Support'
        }"
    >
      Связаться с поддержкой
    </router-link>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
}
</script>

<style lang="scss" src="./style.scss"/>
