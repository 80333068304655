<template>
  <div
      :class="['workspace', {
      'workspace_without-sidebar': !displaySidebarByUserRole
    }]"
  >
    <app-sidebar
        v-model="isSidebarOpen"
        v-if="displaySidebarByUserRole"
    >
      <template #prepend>
        <!--          <router-link
                      v-if="authUser?.role !== 'admin'"
                      :to="{name: 'UserCabinet'}"
                      class="sidebar__list__item"
                  >
                    <div class="sidebar__list__item__header">
                      <div class="sidebar__list__item__title">
                        <mtSvg
                            name="userEdit"
                            color="#00788C"
                            class="sidebar__list__item__title__icon"
                        />

                        Кабинет
                      </div>
                    </div>
                  </router-link>-->
      </template>
    </app-sidebar>

    <div class="wrapper">
      <app-header
          :class="{
            'header_sidebar-exist': displaySidebarByUserRole
          }"
          v-model:is-menu-opened="isSidebarOpen"
      />

      <main class="main">
        <slot />
      </main>

      <app-footer v-if="isFooterShow" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import AppHeader from "@/components/layouts/AppHeader/index.vue";
import mtSvg from "@/components/UI/mtSvg/index.vue";
import AppPreloader from "@/components/workspace/AppPreloader.vue";
import AppSidebar from "@/components/workspace/AppSidebar.vue";
import AppFooter from "@/components/layouts/AppFooter/index.vue";
import AuthTokenCache from "@/cache/auth-token.cache";

export default {
  components: {
    AppFooter,
    AppSidebar,
    AppPreloader,
    mtSvg,
    AppHeader,
  },

  data() {
    return {
      isSidebarOpen: true,
      isAuthRequestPending: true,
    };
  },

  computed: {
    ...mapGetters(['authUser']),

    displaySidebarByUserRole() {
      if(!this.authUser) return;
      const roles = ['respondent', 'expert', 'inspector'];
      return !roles.includes(this.authUser.role.slug);
    },

    isFooterShow () {
      if(!this.authUser) return;
      return ['expert', 'inspector', 'respondent'].includes(this.authUser.role.slug);
    }
  },

  methods: {
    ...mapMutations('assessmentPlans/plan', ['clearExpRespPlans']),
  },


};
</script>

<style lang="scss">
body {
  position: relative;
  min-height: 100vh;
  width: 100%;
}

.workspace {
  display: flex;

  .sidebar_open ~ .wrapper {
    width: calc(100% - 279px);

    @include max-md {
      width: calc(100% - 100px);
    }

    @include max-sm {
      width: 100%;
    }
  }

  .wrapper {
    //overflow: hidden;
    position: absolute;
    width: calc(100% - 100px);
    right: 0;
    transition: all 0.5s ease;

    .main {
      background: #ffffff;
      padding: 20px;
      margin-bottom: 30px;
      min-height: calc(100vh - 150px - 85px);

      @include min-lg {
        padding: 20px 40px;
      }
    }
    @include max-sm{
      width: 100%;
      left: 0;

      .main {
        padding: 20px 15px;
      }
    }
  }

  &_without-sidebar {
    .wrapper {
      width: 100%;
      left: 0;
    }
  }
}
</style>