<script setup>
import { onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useValidations, getVuelidateError } from '@/plugins/vuelidate-wrapper'
import useToast from '@/components/UI/AppToast/useToast'
import { useRouter } from 'vue-router'
import mtButton from '@/components/UI/mtButton/index.vue'
import validation from '@/plugins/validation.js'
import AuthWrapper from '@/components/workspace/auth/AuthWrapper/index.vue'
import AuthApi from '@/api/auth.api.ts'
import useVuelidate from '@vuelidate/core'
import AppInput from '@/components/UI/AppInput/AppInput.vue'
import AppInputPassword from '@/components/UI/AppInputPassword.vue'

const { required, email } = useValidations()

const rules = {
  email: {
    required,
    email,
  },
  password: {
    required,
  },
}

const router = useRouter()
const store = useStore()
const payload = reactive({
  email: null,
  password: null,
})
const commonErrorMessage = ref(null)
const v$ = useVuelidate(rules, payload)
const requestPending = ref(false)
const { toast } = useToast()

async function signIn() {
  const isValid = await v$.value.$validate()

  if (!isValid) {
    return
  }

  requestPending.value = true

  store.dispatch('signIn', payload)
      .then(async (response) => {
        const status = response.data.type
        await store.dispatch('getAuthUser')

        if (status === 'signin') {
          router.push('/')
        } else if (status === 'finish_register') {
          router.push({
            name: 'FinishSignup'
          })
        }
      })
      .catch((error) => {
        const status = error.status;
        if(status === 403) {
          if(error.data.errorCode === 'account_blocked') {
            commonErrorMessage.value = 'Указанная учетная запись отключена';
          }
        }
        if (status === 404) {
          if (error.data.message === 'The specified account has been disabled') {
            commonErrorMessage.value = 'Указанная учетная запись отключена'
          } else {
            commonErrorMessage.value = 'Неверный email или пароль'
          }
        }
      })
      .finally(() => {
        requestPending.value = false
      })
}
</script>

<template>
  <auth-wrapper>
    <template #title>Войти в кабинет</template>
    <template #body>
      <form @submit.prevent autocomplete="off">
        <div class="auth__fields">
          <app-input
            v-model="payload.email"
            :errors="v$.email.$errors"
            @keyup.enter="signIn()"
          >
            <template #label> Email </template>
          </app-input>

          <app-input-password
            v-model="payload.password"
            @keyup.enter="signIn()"
            :errors="v$.password.$errors"
          >
            <template #label> Пароль </template>
          </app-input-password>
        </div>
        <div
          :class="[
            'auth__common-error',
            {
              'auth__common-error--visible': !!commonErrorMessage,
              },
          ]"
        >
          {{ commonErrorMessage }}
        </div>
        <div class="auth__actions">
          <mt-button
              class="auth__actions__btn"
              :loading="requestPending"
              @click="signIn()"
          >
            Продолжить
          </mt-button>
          <div class="auth__actions__link">
            <router-link
                class="auth__actions__link__value"
                :to="{ name: 'Forgot' }"
            >
              Забыли пароль?
            </router-link>
          </div>
        </div>
      </form>


    </template>
  </auth-wrapper>
</template>


