import api from '@/network';
import { generateId, } from '@/services/Mathe';
import { respExpPlanSingleInputAdapter, respondentsAdapter } from '@/adapters/assessmentPlans/expRespPlans.adapter';
import useToast from "@/components/UI/AppToast/useToast";

export default {
  state: {
    respondents: [],
    respondentsMeta: {},
    assessmentPlan: {},
    filters: [],
    PlanEditdataExd: {
      interpritation: [],
      files: [],
    },
    participants: [],
    results: [],
    settings: {},
    isLoading: false,
  },

  getters: {
    rows(state) {
      return [
        {
          title: 'Актуальные задания',
          items: state.assessmentPlan?.tests?.active
        },
        {
          title: 'Выполненные задания',
          items: state.assessmentPlan?.tests?.finished
        }
      ]
    },
    planEditdataExd(state) {
      return state.PlanEditdataExd;
    },
    tests(state) {
      return state.PlanEditdataExd.structure ?? [];
    },
    participants(state) {
      return state.participants;
    },
    interpritation(state) {
      return state.PlanEditdataExd.interpritation ?? [];
    },
    results(state) {
      return state.results;
    },
    settings(state) {
      return state.settings;
    },
  },

  actions: {
    async fetchPlan({ commit }, payload = {}) {
      if(!payload.withoutLoading) commit("setLoading", true);

      const response = await api.get(`plans/${payload.uuid}`, { params: payload.params ? payload.params : null });
      const data = response.data.data;
      if (data) {
        commit('updatePlanStore', data);
        commit('updateExpRespAssessmentPlan', { ...respExpPlanSingleInputAdapter(data), meta: response.data.meta });
        commit("setLoading", false)
        return data
      }
      commit('updatePlanStore', response.data);
      commit('updateExpRespAssessmentPlan', response.data);
      commit("setLoading", false)
    },
    async setPlanProgress({ commit }, payload = {}) {
      const response = await api.get(`plans/${payload.uuid}`, { params: payload.params ? payload.params : null });
      commit('SET_PLAN_PROGRESS', respExpPlanSingleInputAdapter(response.data.data));
    },
    async fetchRespondents({ commit }, payload = {}) {
      let path = '';
      if (['expert', 'inspector'].includes(this.getters.authUserRoleSlug)) {
        path = `plans/${payload.uuid}/expert/respondents`;
      } else {
        path = `plans/${payload.uuid}/respondents`;
      }
      const response = await api.get(path, { params: payload.params ?? null });
      const respondents = response.data.data;
      commit('updateRespondents', { respondents: respondentsAdapter(respondents), meta: response.data.meta });
    },
    async completePlan({ commit }, planUuid) {
      await api.put(`/plans/${planUuid}/completed`).then(() => {
        commit('completePlan')
      });
    },
    async resumePlan({ commit }, planUuid) {
      await api.put(`/plans/${planUuid}/resume`).then(() => {
        commit('completePlan', false)
      });
    },
    async fetchPlanSettings({ }, { uuid, }) {
      const {data} = await api.get(`plans/${uuid}/settings`);
      return data.data;
    },
    async fetchPlanSettingsNotifications({ }, { uuid, search, }) {
      let params = {};

      if (search) {
        params = {
          ...params,
          settings: {
            value: search,
          },
        };
      }

      const {data} = await api.get(`plans/${uuid}/user-notifications`, { params, });
      return data.data;
    },

    async updatePlanSettings({ }, { uuid, payload, }) {
      const {data} = await api.put(`plans/${uuid}/settings`, payload);
      return data.data
    },

    async enableSelfRegistrationLink({ }, { uuid }) {
      const { data } = await api.put(`plans/self_registration/enable`, { uuid });

      return data;
    },

    async disableSelfRegistrationLink({ }, { uuid }) {
      const { data } = await api.put(`plans/self_registration/disable`, { uuid });

      return data;
    },

    async updateSelfRegistrationLink({ state }, { uuid }) {
      const { data } = await api.put(`plans/self_registration/link_update`, {
        uuid
      });

      Object
          .keys(state.settings.selfRegistration)
          .forEach(key => {
            if (state.settings.selfRegistration[key].uuid === data.uuid) {
              state.settings.selfRegistration[key].url = data.url
            }
          })

      return data;
    },

    async updatePlanSettingsNotifications({ getters, }, { uuid, }) {
      const USERS_TO_UPDATE = getters.settings
        .notificationSettings
        .filter(user => user.status)
        .map(user => user.uuid);

      const response = await api.post(`plans/${uuid}/user-notifications`, USERS_TO_UPDATE);

      return response.data.data;
    },

    async deletePlan({ }, payload = {}) {
      return await api.delete(`plans/${payload.uuid}`);
    },
    async updatePlan({ }, payload) {
      payload.planFiles = payload.files.map(file => file.uuid);
      return await api.put(`plans/${payload.uuid}`, payload);
    },

    async setPlanStore({ commit, state }, payload = {}) {

      commit('updateStore', {
        entity: 'PlanEditdataExd',
        payload: {
          ...state.PlanEditdataExd,
          ...payload,
        },
      });
    },
    async setPlanSettingsStore({ commit, getters, }, payload = null) {
      if (!payload) return;

      commit('updatePlanSettings', {
        ...getters.settings,
        ...payload,
      });
    },

    async setPlanEditdataExd(ctx, PlanEditdataExd) {
      ctx.commit('updatePlanEditdataExd', PlanEditdataExd);
    },
  },

  mutations: {
    completePlan(state, val = true) {
      state.assessmentPlan.isCompleted = val;
    },
    moveTestToFinished(state, test) {
      test.isConfirmed = true;
      test.isCompleted = true;
      test.btn.label = 'Пройдено';
      state.assessmentPlan.tests.active = state.assessmentPlan.tests.active.filter(item => item.uuid !== test.uuid);
      state.assessmentPlan.tests.finished.unshift(test);
    },
    updateExpRespAssessmentPlan(state, data) {
      state.assessmentPlan = data;
    },
    SET_PLAN_PROGRESS(state, item) {
      state.assessmentPlan.progress = item.progress;
    },
    updatePlanStore(state, payload = {}) {
      state.PlanEditdataExd = {
        ...payload,
        files_expert: []
      };
      if (state.PlanEditdataExd?.structure) {
        state.PlanEditdataExd.structure = state.PlanEditdataExd.structure.map(task => {
          if (task.materials.length) task.files = task.materials
          return task
        })
      }
    },
    updateTests(state, tests) {
      state.PlanEditdataExd.structure = tests;
    },
    updateFilters(state, filters) {
      state.filters = filters;
    },
    updateRespondents(state, { respondents, meta }) {
      state.respondents = respondents;
      state.respondentsMeta = meta;
    },
    updatePlanSettings(state, settings) {
      state.settings = settings;
    },
    updateStore(state, { entity, payload, }) {
      state[entity] = payload;
    },
    setLoading(state, payload) {
      state.isLoading = payload
    }
  },
}