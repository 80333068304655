import api from "@/network";
import AuthApi from '@/api/auth.api.ts';
import AuthTokenCache from "@/cache/auth-token.cache";

export default {
    actions: {
        async getAuthUser({commit}) {
            return await AuthApi.getUserInfo()
                .then((response) => {
                    commit('UPDATE_USER_DATA', response.data.data);
                })
                .catch(async (error) => {
                    AuthTokenCache.clear();
                    Promise.reject(error);
                    throw error;
                })
        },

        async signIn({commit,}, payload) {
            return await AuthApi.signIn(payload)
                .then((response) => {
                    commit('UPDATE_USER_DATA', response.data.data);
                    AuthTokenCache.set(response.data.data.token);
                    return response;
                });
        },

        async confirmPassword({commit,}, payload) {
            const response = await api.put('auth/confirm', {password: payload});

            commit('UPDATE_USER_DATA', {
                uuid: response.data.data.uuid,
                token: response.data.data.token,
                status: response.data.data.status,
            });

            return response;
        },

        async impersonate({commit}, payload) {
            const response = await AuthApi.impersonate(payload);
            AuthTokenCache.set(response.data.token);
            return response;
        },
    },

    mutations: {
        UPDATE_USER_DATA(state, payload) {
            state.authUser = payload;
        },
    },

    state: {
        authUser: null
    },

    getters: {
        authUserRoleSlug(state) {
            return state.authUser?.role?.slug;
        },
        isAdmin(state, getters) {
            return getters.authUserRoleSlug === 'admin';
        },
        isExpert(state, getters) {
            return getters.authUserRoleSlug === 'expert';
        },
        isRespondent(state, getters) {
            return getters.authUserRoleSlug ==='respondent';
        },
        authUser(state) {
            return state.authUser;
        }
    },
}