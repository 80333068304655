<template>
  <app-form-block
    class="mt-switch-wrapper"
    @click="toggle"
    :hide-details="hideDetails"
  >
    <div
      :class="[
        'mt-switch',
        {
          'mt-switch__circle_checked': switched
        }
      ]"
    >
      <div class="mt-switch__circle" />
    </div>

    <label class="mt-switch__label">
      {{ label }}
      <slot name="label" />
    </label>
  </app-form-block>
</template>

<script>

import AppFormBlock from "@/components/UI/AppFormBlock/AppFormBlock.vue";

export default {
  name: 'mtSwitch',
  components: {AppFormBlock},
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      switched: false
    }
  },
  methods : {
    toggle () {
      this.switched = !this.switched
      this.$emit('update:modelValue', this.switched)
      this.$emit('change', this.switched)
    }
  },
  mounted () {
    if(this.modelValue !== undefined) {
      this.switched = this.modelValue
    }
    else if(this.value !== undefined) {
      this.switched = this.value
    }
  },
  watch: {
    modelValue (newValue) {
      this.switched = newValue
    },
    value (newValue) {
      this.switched = newValue
    }
  }
}
</script>

<style lang="scss">
.mt-switch {
  width: 44px;
  height: 22px;
  display: inline-flex;
  background: #E9E9EA;
  border-radius: 100px;
  position: relative;
  transition: all ease-in-out .3s;
  &-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__label {
    font-size: 14px;
    margin-left: 8px;
    font-weight: 500;
    cursor: pointer;
  }
  &__circle {
    width: 18px;
    cursor: pointer;
    height: 18px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    transition: all ease-in-out .2s;
    left: 2px;
    top: 2px;
    &_checked {
      background-color: #00788C;
      & > .mt-switch__circle {
        left: 24px;
      }
    }
  }
}

</style>
