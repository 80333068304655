<template>
  <auth-user-header-block-wrapper>
    <template #title>
      {{ authUser?.full_name }}
    </template>
    <template #subtitle>
      {{ authUser?.role.name }}
    </template>
  </auth-user-header-block-wrapper>
</template>

<script>
import AuthUserHeaderBlockWrapper from "@/components/workspace/auth/AuthUserHeaderBlock/AuthUserHeaderBlockWrapper.vue";
import {mapGetters} from "vuex";

export default {
  components: {AuthUserHeaderBlockWrapper},
  name: "DefaultAuthUserHeaderBlock",
  computed: {
    ...mapGetters(['authUser']),
  },
}
</script>