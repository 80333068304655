<script setup lang="ts">
import Datepicker from '@vuepic/vue-datepicker'
import type {Ref} from 'vue'
import {computed, ref, watch} from 'vue'
import {format} from 'date-fns'
import {addLeadingZero} from '@/services/utils.js'
import {createDateFromDDMMYYY} from '@/services/date.js'
import AppFormBlock from "@/components/UI/AppFormBlock/AppFormBlock.vue";

enum DateTypeEnum {
  Date = 'date',
  Time = 'time',
  DateTime = 'dateTime',
}

type Props = {
  modelValue: string | null
  type?: DateTypeEnum
  readonly?: boolean
  minDateTomorrow?: boolean
  fromDate?: string
  maxDate?: string
  minDate?: string
  toDate?: string
  format?: string
  label?: string
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  type: DateTypeEnum.Date,
  readonly: false,
})

const emit = defineEmits(['update:modelValue'])
function getFormattedDate(value: string | null) {
  if (!value) return null
  const dateInstance = new Date(value)
  return format(dateInstance, computedFormat.value)
}
const computedFormat: Ref<string> = computed(() => {
  if (props.format) return props.format

  if (typeIsDateTime.value) return 'dd-MM-yyyy, HH:mm'

  if (typeIsOnlyTime.value) return 'HH-mm'

  return 'dd-MM-yyyy'
})
const date = ref(
  props.modelValue ? new Date(props.modelValue) : null,
)
emit('update:modelValue', getFormattedDate(props.modelValue))

const minDate = computed(() => {
  if (!props.minDate) return
  return createDateFromDDMMYYY(props.minDate).toISOString()
})

const maxDate = computed(() => {
  if (!props.maxDate) return
  return createDateFromDDMMYYY(props.maxDate).toISOString()
})

const typeIsOnlyTime = computed(() => {
  return props.type === 'time'
})

const typeIsDateTime = computed(() => {
  return props.type === 'dateTime'
})

function setDate() {
  if (date.value === null) {
    emit('update:modelValue', null)
    return
  }
  if (props.type === 'time') {
    date.value.hours = addLeadingZero(date.value.hours)
    date.value.minutes = addLeadingZero(date.value.minutes)
    emit('update:modelValue', `${date.value.hours}:${date.value.minutes}`)
  } else if (props.type === 'date') {
    date.value = new Date(date.value);

    emit('update:modelValue', getFormattedDate(date.value))
  } else {
    emit('update:modelValue', date.value)
  }
}

const isOpened = ref(false)

/* watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      date.value = createDateFromDDMMYYY(props.modelValue)
    }
  },
)

watch(() => props.modelValue, (value) => {
  if(!value) {
    date.value = null
  }
}) */
</script>

<template>
  <app-form-block class="date-time-picker">
    <label
        :class="[
        'date-time-picker__label',
        {
          'date-time-picker__label--active': isOpened || date,
        },
      ]"
    >
      {{props.label}}
      <slot name="label" />
    </label>
    <datepicker
      auto-apply
      v-model="date"
      :enable-time-picker="typeIsOnlyTime || typeIsDateTime"
      :format="computedFormat"
      :min-date="minDate"
      :max-date="maxDate"
      :readonly="readonly"
      :time-picker="typeIsOnlyTime"
      :month-change-on-scroll="false"
      :text-input="true"
      input-class-name="date-time-picker__input"
      month-name-format="long"
      locale="ru"
      utc
      @update:modelValue="setDate()"
      @open="() => isOpened = true"
      @close="() => isOpened = false"
    />
  </app-form-block>
</template>

<style lang="scss" src="./AppDateTimePicker.scss" />
